/* eslint-disable @typescript-eslint/no-explicit-any */
import { QuantityMode } from 'src/api/recipes'
import { CreateCustomProductPayload } from 'src/api/products'
import {
  StemTypes,
  ProductDetailKey,
  ProductImageType,
  PhotosNeeded,
  PreferenceKey,
  ProposalUnitType,
  ProposalUnitItemType,
  ExperienceType,
  OrderState,
  SupplierType,
  UnitCategory,
  TemplateProductType,
  ProposalUnitProductType,
  TemplateProposalUnitType,
  StemErrorStatus,
  OrderStemIssueKey,
  InvoiceStatus,
  RecipeFormulaStemRole
} from './enum'

export interface RecipeStem {
  id?: number
  stem: Stem
  stemId: number
  recipe: Recipe
  recipeId: number
  supplierStem: SupplierStem
  supplierStemId: number
  quantity: number
  sequenceNum: number
}
export interface RecipeModel {
  id?: number
  eventOrder: EventOrder
  eventOrderId: number
  // unitProduct: UnitProduct
  unitProductId: number
  product: Product
  productId: number
  recipeStems: RecipeStem[]
  quantity: number
  styleNotes: string
  sequenceNum: number
  approved: boolean
  recipeHardGoods: RecipeHardGood[]
  prepTimeMinutes: number
  designTimeMinutes: number
  installTimeMinutes: number
  loadingTimeMinutes: number
  photosNeeded: PhotosNeeded
  quantityMode: QuantityMode
}

export interface ProposalUnit {
  proposal: Proposal
  proposalId: number
  unitType: ProposalUnitType
  quantity: number
  itemType: ProposalUnitItemType
  sequenceIndex: number
  unitName: string
  shouldRenderDescription: boolean
  note: string
  deletedAt?: Date
  // unitProducts: UnitProduct[];
  proposalInspirationPhotos: ProposalInspirationPhotos[]
  // proposalUnitComments: ProposalUnitComment[];
}

export interface ProposalInspirationPhotos {
  id?: string
  imageUrl: string
  // customerPortalUser: CustomerPortalUser;
  customerPortalUserId: number
  proposalUnit: ProposalUnit
  proposalUnitId: number
  proposal: Proposal
  proposalId: number
  isHidden: boolean
}

export interface Proposal {
  id?: string
  event: Event
  eventId: number
  // presentation: ProposalPresentation;
  // units: ProposalUnit[];
  // totals: ProposalTotal[];
  // extraFees: ExtraFee[];
  // audits: ProposalAudits[];
  totalItemAmountLow: number
  totalItemAmountHigh: number
  totalTaxLow: number
  totalTaxHigh: number
  taxLocation: string
  totalSelected: number
  totalTaxSelected: number
  taxRate: number
  notes: string
  internalNotes: string
  proposalInspirationPhotos: ProposalInspirationPhotos[]
  grandTotal: number
  preTaxRevenue: number
}

export interface ColorPalette {
  id: number
  name: string
  colors: any
  colorList: string[]
  images: any
  customerName: string
  location: string
  orbConfig: {
    orb_colors: string[]
  }
}

export interface EventModel {
  id?: number
  customer: Customer
  date: Date
  city: string
  state: string
  venueStreetAddress: string
  venueName: string
  primaryCustomerAttire: string
  secondaryCustomerAttire: string
  guestCount: number
  guestCountUnknown: boolean
  additionalBouquetsNeeded: number
  additionalBoutsNeeded: number
  additionalItemsUnknown: boolean
  // weddingAttire: WeddingAttire;
  budget: number
  dayOfPointOfContactName: string
  dayOfPointOfContactPhoneNumber: string
  dealId: number
  otherInformation: string
  otherBookedVendors: string[]
  covidImpact: boolean
  planningStage: string
  // needs: EventNeed[];
  // proposals: Proposal[];
  // locations: EventLocation[];
  // market: Market;
  marketId: number
  // customerPaymentPlan: CustomerPaymentPlan;
  proposals: Proposal[]
  colorPalette: ColorPalette
  isEventServices: boolean
  serviceLevel: string
}

/* eslint-disable camelcase */
export interface EventOrder {
  event: EventModel
  eventId: number
  recipes: RecipeModel[]
  orders: IOrders[]
  externalId: string
  orderSuppliers: OrderSupplier[]
  recipient: string
  address_1: string
  address_2: string
  city: string
  state: string
  zip: string
  addressConfirmed: boolean
  id?: number
}

export interface RecipeTemplateStem {
  id?: number
  recipeTemplate: RecipeTemplate
  stem: Stem
  stemId: number
  quantity: number
}

export interface RecipeTemplateHardGood {
  recipeTemplate: RecipeTemplate
  hardGood: HardGood
  hardGoodId: number
  quantity: number
}

export interface RecipeTemplate {
  id?: number
  product: Product
  recipeTemplateStems: RecipeTemplateStem[]
  recipeTemplateHardGoods: RecipeTemplateHardGood[]
}

export interface StylePreferenceOld {
  colors: string[]
  proposalLink: string
}

export interface ProductOld {
  name: string
  id: string
  url: string
}

export interface FloralEvent {
  eventId: number
  customerId: number
  date: string
  name: string
}

export interface StemRow {
  name: string
  type: StemTypes
  stemCount: number
  bunchSize: number
}

export interface Recipe {
  productName: string
  productId: number
  productImageUrl: string
  productQuantity: number
  stems: StemRow[]
}

export interface StylePreferenceModel {
  customer: Customer
  preferenceKey: PreferenceKey
  preferenceValues: string[]
  preferenceUnknown: boolean
}

export interface Customer {
  id: number
  displayName: string
  name: string
  partnerName: string
  firstName: string
  lastName: string
  emailAddress: string
  phoneNumber: string
  stylePreferences: StylePreferenceModel[]
  pinterestBoard: string
  loveStory: string
}

export interface Venue {
  name: string
  streetAddress?: string
  locationType?: string
}

export interface QuizProducts {
  personalItems: string[]
  tableArrangements: string[]
}

export interface ProjectImageAttribution {
  photographerName?: string
  photographerWebsite?: string
  photographerInstagram?: string
}

export interface ProductImage {
  id?: number
  imageUrl: string
  description?: string
  type: ProductImageType
  eventId?: number
  unitProducts?: UnitProduct[]
  deletedAt?: Date
  attribution?: ProjectImageAttribution
  signedUrl?: string
  product?: { id: number }
  newProductId?: number
}

export interface ProductVariant {
  id: number
  description: string
  price: number
  defaultUnitType: string
  priceUnit: string
  default: boolean
  priceUnitDisplay: string
  defaultPrepTimeMinutes: number
  defaultDesignTimeMinutes: number
  defaultInstallTimeMinutes: number
  defaultLoadingTimeMinutes: number
  defaultInstructions: string
  defaultPhotosNeeded: PhotosNeeded[]
  product: Product
}

export interface UnitProduct {
  proposalUnit: ProposalUnit
  productVariant: ProductVariant
  productImage: ProductImage
  isSelected: boolean
  sequenceIndex: number
  shortDescriptionOverride: string
  quantitySelected: number
  price: number
  deletedAt?: Date
  selectedByAdmin: boolean
}

export interface ProductDetail {
  id: number
  detailKey: ProductDetailKey
  detailValues: string[]
}

export interface ProductCategory {
  id: number
  name: string
  externalId: string
}

export interface Product {
  id: number
  name: string
  shortDescription: string
  longDescription: string
  tags: string[]
  images: ProductImage[]
  variants: ProductVariant[]
  productDetails: ProductDetail[]
  productCategory: ProductCategory
  sku: string
  isSelected?: boolean
  templates?: ProposalTemplate[]
}

export interface EventProduct {
  name: string
  productId: number
  unitProductId: number
  imageUrl: string
  price: string
  priceUnitDisplay: string
}

export interface ReducerProduct {
  name: string
  id: number
  unitProductPrice: string
  quantity: number
  imageUrl: string
  price: string
  styleNotes: string
  stems: StemOrderUnit[]
  recipeId?: number
  approved?: boolean
  sequenceNum?: number
  recipeHardGoods: RecipeHardGood[]
  prepTimeMinutes?: number | null
  designTimeMinutes?: number | null
  installTimeMinutes?: number | null
  loadingTimeMinutes?: number | null
  instructions?: string | null
  photosNeeded?: PhotosNeeded[]
  quantityMode?: QuantityMode
  imageOverride?: string
  productDetails?: ProductDetail[]
  nickName?: string
  images: ProductImage[]
  variants: ProductVariant[]
  unitProduct: UnitProduct | null
}

export interface StylePreference {
  styles: string[]
  colors: string[]
}

export interface PortalUserModel {
  firstName: string
  lastName: string
  emailAddress: string
  phoneNumber: string
  airtableRecordId: string
  // invoices: Invoice[];
  // portalUserStripeAccounts: PortalUserStripeAccount[];
  // referralLinks: ReferralLink[];
  nativeDesignGuidesEnabled: boolean
  isAdmin: boolean
  // designerAvailability: DesignerAvailability
  // designerEventEligibilities: DesignerEventEligibility[]
  profilePhotoUrl: string | null
  landingPageUrl: string | null
  address_1: string | null
  address_2: string | null
  city: string | null
  state: string
  zip: string | null
  deliveryInstructions: string | null
  isDeliverySameHomeAddress: boolean
  deliveryName: string | null
  deliveryAddress_1: string | null
  deliveryAddress_2: string | null
  deliveryCity: string | null
  deliveryState: string | null
  deliveryZip: string | null
  // designerHardGoods: DesignerHardGood[]
  ecFirstName: string | null
  ecLastName: string | null
  ecRelationship: string | null
  ecPhoneNumber: string | null
  // market: Market
  marketId: number
}

export interface IAirtableDesignerInfo {
  id: string
  name: string
  email: string
  phone: string
  addressDelivery: string
  pay: string
  addressHome: string
  sustainableDesign: string
}

export interface AirtablePhoto {
  id: string
  width: number
  height: number
  url: string
  filename: string
  size: number
  type: string
  thumbnails: any
}

export interface IAirtableTicketing {
  id: string
  linkToDeal: string[]
  type: string
  status: string
  dueDate: string
  owner: any
  completedAt: string
  subtasks: string
  originalRecipeWriter: string
  dealId: string[]
}

export interface IAirtableInfo {
  title: string
  eventDate: string
  numberOfItems: string
  linkToProposal: string
  dealValue: number
  orderNumber: string
  weddingLocation: string
  designSpecialist: string
  designerBooked: IAirtableDesignerInfo[]
  designerPhotos: AirtablePhoto[]
  deliveryServiceSelect: string
  designerRate: string[]
  breakdownMinutes: string
  roundTripTravelMinutes: string
  roundTripTravelEstimate: string
  designerBookedField: string[]
  ticketings: IAirtableTicketing[]
  ndg_link: string
  deliveryPickupLocation: string
  designer_support_specialist: any[]
}

export interface FloralManager {
  emailAddress: string
  firstName: string | null
  lastName: string | null
  avatar: string | null
  description: string | null
  calendlyLink: string | null
  slackId: string | null
  airtableId: string | null
  roundRobin: boolean
  weightedRoundRobin: number
  roundNumber: number
  ownedUtmParameters: any
}

export interface EventInfo {
  id: number
  customerInfo: Customer
  date: string
  budget: string
  guestCount: string
  venue: Venue
  experienceType: ExperienceType
  fulfillmentOwner: FloralManager | null
  quizProducts?: QuizProducts
  productsNeeded?: string[]
  stylePreference?: StylePreference
  airtableInfo?: IAirtableInfo
  selectedProducts?: any[]
  proposal: {
    id: number
    notes: string
    inspirations: { id: number; imageUrl: string }[]
    grandTotal: number
    totalTaxSelected: number
  }
  palette: {
    id: number
    name: string
  }
  designerRate: number
  designerPortalUser?: PortalUserModel[]
  confirmVisible?: boolean
  designerOrderConfirmation?: boolean
}

export interface EventInfoAndAirEventInfo extends EventModel {
  airtableInfo?: IAirtableInfo
}

export interface StemCategory {
  id: number
  name: string
  externalId: string
}

export interface Stem {
  stemCategory: StemCategory
  name: string
  id: number
  color: string[]
  type: string[]
  variety: string
  externalId: string
  recipeStemId: number
  imageUrl: string
  frequency: number
  supplier?: string
  supplierStemId?: number
  pricePerStem?: string
  pricePerBunch?: string
  bunchSize?: string
  supplierStem?: SupplierStem[]
  availability?: string[]
  errorStatus?: StemErrorStatus
  orderStems?: IOrderStemsOfOrder[]
  errorReportHistory?: IOrderStemsOfOrder[]
  deprecated?: boolean | null
}

export interface SupplierStem {
  id: number
  insertedAt: Date
  updatedAt: Date
  name: string
  color: string[]
  length: string
  pricePerStem: string
  bunchSize: string
  pricePerBunch: string
  minimumOrder?: number
  inStock: boolean
  supplier: string
  origin?: string
  externalId: string
  imageUrl: string
  supplierId: string
  stemCategory: StemCategory
  stem?: Stem
  supplierRef?: Supplier
  supplierRefId?: number
  defaultSubstitutes?: string
}

export interface StemOrderUnit extends Stem {
  stemsNeeded: number
  bunchesNeeded: number
  totalStemPrice?: number
  stemRole?: RecipeFormulaStemRole
  roleIndex?: number
  sequenceNum?: number
}

export interface SupplierStemOrderUnit extends StemOrderUnit {
  inStock: boolean
  origin: string
}

export interface ResponseGenerator {
  config?: any
  data?: any
  headers?: any
  request?: any
  status?: number
  statusText?: string
}

export interface IStringDynamicPrototype {
  [label: string]: string
}

export interface AirtableCategory {
  name: string
  id: string
  stems: string[]
}

export interface StemSubstitute {
  id?: number
  orderStem: IOrderStemsOfOrder
  stem: Stem
  supplierStem: SupplierStem
}

export interface IOrderStemsOfOrder {
  id: number
  orderId: number
  quantity: number
  stem: Stem
  stemId: number
  supplierStem: SupplierStem
  supplierStemId: number
  stemSupplier: Supplier | null
  stemSupplierId: number | null
  supplier: null | string
  selected?: boolean
  price: string
  substitutes: string | null
  subStem?: Stem
  subStemId?: number
  stemSubstitutes?: StemSubstitute[]
  issueKey?: OrderStemIssueKey
  issueCount?: number
  issueNote?: string
  resourceNeeded?: boolean
}

export interface IOrders {
  id: number
  event?: EventModel
  eventId: number
  eventOrderId: number
  eventOrder: EventOrder
  supplier: string
  orderStems: IOrderStemsOfOrder[]
  orderUrl: string
  total: number
  orderHardGoods: OrderHardGood[]
  deliveryDate: Date
  productTotal: number
  projectedFreightTotal: number
  projectedProductTotal: number
  projectedTotal: number
  freightTotal: number
}

export interface IAirtableEvents {
  date: string
  name: string
  eventId: number
}

export enum LOADING_STATUS {
  START = 1,
  END = -1
}

export interface DesignerHardGood {
  hardGood: HardGood
  quantity: number
}

export interface HardGood {
  id?: number
  name: string
  type: string
  price: string
  description: string
  sku: string
  orderQuantityType: string
  conversionFactor: number
  recipeQuantityType: string
  maxOrderQuantity?: number
  designerHardGoods: DesignerHardGood[]
}

export interface RecipeHardGood {
  id?: number
  recipe: Recipe
  recipeId?: number
  hardGood: HardGood
  hardGoodId?: number
  quantity: number
  quantityType: string
}

export interface OrderHardGood {
  id?: number
  order: IOrders
  orderId: number
  hardGood: HardGood
  hardGoodId: number
  quantity: number
  supplier: string
  supplierId: string
  price: string
  selected?: boolean
  hardGoodSupplier: Supplier | null
  hardGoodSupplierId: number | null
  updating?: boolean
}

export interface Supplier {
  id: number
  name: string
  type: SupplierType
  orderRecipients: string[]
  substitutionsRequired: boolean
  minimumStemCount: number | null
  batchingEnabled: boolean
  batchingLeadTimeDays: number | null
  blackOutDates: {
    weekly_blackout?: string[]
    weekly_avoided?: string[]
    holidays?: string
  }
  preferred: boolean
  avgHgFreight: number
  avgStemFreight: number
}

export interface Shipstation {
  id: number
  insertedAt: string
  updatedAt: string
  shipstationOrderId: string
  shipstationOrderKey: string
  shipmentUrl: string
  orderSupplierId?: number
  orderHardGoodId?: number
}

export interface OrderSupplier {
  id?: number
  eventOrder: EventOrder
  eventOrderId: number
  supplier: Supplier
  supplierId: number
  orderState: OrderState
  boxCount: number
  boxCounts: any
  deliveryDate: Date
  shipstations: Shipstation[]
}

export interface EventOrderStem {
  id?: number
  eventOrder: EventOrder
  eventOrderId: number
  stem: Stem
  stemId: number
  stemRole?: string
  roleIndex?: number
  sequenceNum?: number
}

export interface IUpdateProductImage {
  attribution?: ProjectImageAttribution
  type?: ProductImageType
  eventId?: number
}

export interface CreateProductImagePayload {
  attribution?: ProjectImageAttribution
  type?: ProductImageType
  imageUrl: string
  productId: number
}

interface IUpdateStem {
  stemId: number
  quantity: number
}

interface IUpdateHardGood {
  hardGoodId: number
  quantity: number
}

export interface ICreateRecipePayload {
  stems: IUpdateStem[]
  hardGoods: IUpdateHardGood[]
}

export interface ICreateNewProductFromImage {
  productPayload: CreateCustomProductPayload
  recipe?: ICreateRecipePayload
  isApproved?: boolean
  eventId: number
  attribution?: ProjectImageAttribution
}

export type ProposalTemplate = {
  id: number
  displayName: string
  colorPaletteId: number
  updatedAt: string
  colorPalette: ColorPalette
}

export type TemplateProduct = {
  id: number
  templateId: number
  unitType: TemplateProposalUnitType
  proposalUnitCategory: UnitCategory
  productType: TemplateProductType
  unitProductType: ProposalUnitProductType
  includesUnitItemType: string[]
  product: Product
  isPreview?: boolean
  sequenceIndex: number
  originalIndex: number
  productId: number
  isSelected?: boolean
  selectedQuantity: number
}

export type ProductPayload = {
  id: number
  templateId: number
  unitType: TemplateProposalUnitType
  proposalUnitCategory: UnitCategory
  productType: TemplateProductType
  unitProductType: ProposalUnitProductType
  isDeleted?: boolean
  productId?: number
  sequenceIndex?: number
  isSequenceIndexChanged?: boolean
  isSelected?: boolean
  selectedQuantity?: number
}

export type TemplateProductPayload = {
  paletteId?: number
  products: ProductPayload[]
}

export type PaginationParams = {
  page?: number
  limit?: number
  isAll?: boolean
}

export type Pagination<T> = {
  count: number
  results: T
}

export type UpdateEventStemsPayload = {
  eventStems: {
    id?: number
    stemId: number
    isDeleted?: boolean
    sequenceNum?: number
    stemRole?: string
    roleIndex?: number
  }[]
}

export type StemPaletteStem = {
  id: number
  stemPaletteId: number
  stemId: number
  stem: Stem
  stemRole?: RecipeFormulaStemRole
  roleIndex?: number
  sequenceNum?: number
}

export type StemPalette = {
  id: number
  name: string
  colorSwatch: string[]
  colorPalette: ColorPalette
  colorPaletteId: number
  paletteStems: StemPaletteStem[]
}

export interface UploadFile {
  id?: string
  isSelected?: boolean
  isMapped?: boolean
  file: File
}

export interface IOption {
  value: string | number
  label: string
}

export interface Invoice {
  date: string
  totalWages: number
  totalReimbursement: number
  additionalNote: string
  attachments: string[]
  status: InvoiceStatus
  type: string
  managerNote: string
  projectName: string
  stripeTransactionId: string
  paidOn: string
  respondedAt: string
  submittedAt: string
  portalUserId: number
  managerId: number
  hardGoodAmount: number
  flowerAmount: number
  additionAmount: number
  deliveryFee: number
  reSourcingWages: number
  designHours: number
  reSourcingHours: number
  eventId: number
}

export interface MarginBreakdownData {
  isPending: boolean
  designerInvoiceApproved: boolean
  missingOrderTotal: boolean
  preTaxTotal: number

  projectedTotal: number
  projectedTotalPercent: number
  projectedStemCost: number
  projectedStemPercent: number
  projectedHardGoodCost: number
  projectedHardGoodPercent: number
  projectedFreightCost: number
  projectedFreightPercent: number

  projectedDesignerCost: number
  projectedDesignerPercent: number

  totalProjectedCost: number
  totalProjectedPercent: number

  actualProductCost: number
  actualProductPercent: number
  actualStemCost: number
  actualStemPercent: number
  actualHardGoodCost: number
  actualHardGoodPercent: number
  actualFreightCost: number
  actualFreightPercent: number

  actualDesignerCost: number
  actualDesignerPercent: number

  actualTotalCost: number
  actualTotalPercent: number
}
